<template>
  <div class="about">
    <img
      src="https://cdn.monstersofthenorth.com/content/Profile_2_small_tiny.jpg"
      alt="Illustrated Profile picture: a guy smiling with green eyes and harsh light on his face, mid-long spiky hair and glasses."
    />
    <div class="container">
      <h1>Who am I?</h1>
      <div class="row">
        <div class="col">
          <p>
            Hey, my name is Benjamin, a Graphics artist gone Indie Game
            Developer! I want to make games that make you feel something. I've
            been dreaming about making games since I was a little kid, and for
            the past 4 years I've been following that dream. I care deeply about
            game accessbility, and it's important to me that people who want to
            play my games are able to do so.
          </p>
          <a href="https://twitter.com/stealcase">
            <i class="fab fa-twitter-square fa-4x fa-fw"></i>
            <div class="sr-only">Twitter</div>
          </a>
          <a href="https://www.instagram.com/stealcase_gamedev/">
            <i class="fab fa-instagram fa-4x fa-fw"></i>
            <div class="sr-only">Instagram</div>
          </a>
          <a href="https://www.youtube.com/channel/UCn7Nx5cUebQtlGFHRsrHWqg?">
            <i class="fab fa-youtube fa-4x fa-fw"></i>
            <div class="sr-only">Youtube</div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
p {
  font-size: 1.31rem;
  text-align: left;
}
img {
  max-width: 30%;
  height: auto;
  border-radius: 50%;
}
</style>
